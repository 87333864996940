import axios, { AxiosResponse } from "axios";
import { CompanyRegisterModel } from "../../models/dto/auth/companyRegister.dto";
import { CustomerRegisterModel } from "../../models/dto/auth/customerRegister.dto";
import { jwtDecode } from "jwt-decode";
import { UserRole } from "../../models/dto/users/user.dto";
import dataLayerHelper from "../../helpers/dataLayerHelper/dataLayerHelper";
import customerService from "../customers/customer.service";


const API_URL = window.config.baseApiUrl;
const registerCompany = (registerModel: CompanyRegisterModel) => {
    return axios.post(API_URL + "/company/application/register", registerModel)
        .then((response) => {
            return response.data;
        });
};
const registerCustomer = (registerModel: CustomerRegisterModel) => {
    return axios.post(API_URL + "/customer/register", registerModel)
        .then((response) => {
            return response.data;
        });
};
const validateCustomerEmail = (emailAddress: string) => {
    return axios.post(API_URL + "/user/forgot-password-send-email", { email: emailAddress }, { headers: { 'Content-Type': 'application/json' } })
        .then((response) => {
            return response.data;
        });
};
const validateChangePasswordHash = (hash: string) => {
    return axios.post(API_URL + "/user/forgot-password-validate-uuid", { uuid: hash })
        .then((response) => {
            return response.data;
        });
};
const changePassword = (hash: string, newPassword: string) => {
    return axios.post(API_URL + "/user/forgot-password-change-password", { uuid: hash, password: newPassword, passwordRepeat: newPassword })
        .then((response) => {
            return response.data;
        });
};
const login = (username: string, password: string, roleRedirectUrls?: { role: string, url: string }[]) => {
    return axios
        .post(API_URL + "/auth/login", {
            username,
            password,
        })
        .then((response) => {
            if (response.data.token) {
                localStorage.setItem("token", JSON.stringify(response.data));
                dataLayerHelper.push({
                    event: "login",
                    user_id: username
                });
                if (isTrainReplacementAdmin())
                    setRoleAndRedirect("TRAIN_REPLACEMENT", "CORPORATE", roleRedirectUrls);
                else if (isUserInRole(UserRole.DealCustomerAdmin) ||
                    isUserInRole(UserRole.DealCustomerBooker) ||
                    isUserInRole(UserRole.DealCustomerFinance))
                    setRoleAndRedirect(UserRole.DealCustomerAdmin, "CORPORATE", roleRedirectUrls);
                else if (isUserInRole(UserRole.CompanyAdmin))
                    setRoleAndRedirect(UserRole.CompanyAdmin, "CORPORATE", roleRedirectUrls);
                else if (isUserInRole(UserRole.TrafficController))
                    setRoleAndRedirect(UserRole.TrafficController, "CORPORATE", roleRedirectUrls);
                else if (authService.isUserInRole("CUSTOMER"))
                    customerService.getContactInfo().then((res) => {
                        setRoleAndRedirect(UserRole.Customer, res.contactInfoType, roleRedirectUrls);
                    });
                else {
                    setRoleAndRedirect(getCurrentUserRole(), "PRIVATE", roleRedirectUrls);
                }
            }
        });
};
const setRoleAndRedirect = (userRole: string, type: "CORPORATE" | "PRIVATE", roleRedirectUrls?: { role: string, url: string }[]) => {
    localStorage.setItem("customerType", type);
    const roleRedirect = roleRedirectUrls?.find(r => r.role == userRole);
    if (roleRedirect)
        window.location.href = roleRedirect.url;

}
const getCurrentUserRole = (): UserRole | string => {
    const user = getCurrentUser();
    if (!user)
        return "";
    const decodedJwt = jwtDecode<any>(user);
    if (decodedJwt.scopes.some((s: string) => s == UserRole.CompanyAdmin))
        return UserRole.CompanyAdmin;
    return decodedJwt.scopes[decodedJwt.scopes.length - 1] as UserRole;
}
const isUserInRole = (role: string): boolean => {
    const user = getCurrentUser();
    if (!user)
        return false;
    const decodedJwt = jwtDecode<any>(user);
    return decodedJwt.scopes.findIndex((s: string) => s == role) != -1;
}
const getCurrentUserName = (): string => {
    const user = getCurrentUser();
    if (!user)
        return "";
    const decodedJwt = jwtDecode<any>(user);
    const scope = decodedJwt.userId;
    return scope;
}
const isAuthenticated = (): boolean => {
    return localStorage.getItem("token") ? true : false;
}
const isSystemAdminOrOwner = (): boolean => {
    if (isUserInRole(UserRole.SystemAdmin) || isUserInRole(UserRole.SystemOwner))
        return true;
    return false;
}
const isDealCustomerAdmin = (): boolean => {
    return isUserInRole("DEAL_CUSTOMER_ADMIN");
}
const isCompanyAdmin = (): boolean => {
    return isUserInRole("COMPANY_ADMIN");
}
const isDealCustomerUser = (): boolean => {
    return isUserInRole(UserRole.DealCustomerUser);
}
const logout = () => {
    dataLayerHelper.push({
        event: "logout",
        user_id: getCurrentUserName()
    });
    localStorage.removeItem("lastContactInfo");
    localStorage.removeItem("lastSearchObject");
    localStorage.removeItem("activeOrganizationId");
    localStorage.removeItem("token");
};

const getCurrentUser = () => {
    var tokenJson = localStorage.getItem("token");
    if (!tokenJson)
        return null;
    return JSON.parse(tokenJson).token;
};

const isTrainReplacementCustomer = () => {
    const user = getCurrentUser();
    if (!user)
        return false;
    const decodedJwt = jwtDecode<any>(user);
    return decodedJwt.trainReplacementCustomer;
};

const isTrainReplacementAdmin = () => {
    return isTrainReplacementCustomer() && isUserInRole("DEAL_CUSTOMER_ADMIN");
};
const isTrafficControlUser = () => {
    return isUserInRole(UserRole.TrafficController);
};


const onAuthStateChanged = (onChange?: any, authState?: boolean) => {
    if (authState)
        onChange(authState);
}
const getPepper = () => {
    return axios.post<null, AxiosResponse<string>>(API_URL + "/auth/pepper")
        .then((response) => {
            localStorage.setItem("pepper", response.data);
            window.location.reload();
        });
}
const authService = {
    registerCompany,
    login,
    logout,
    getCurrentUser,
    registerCustomer,
    getCurrentUserRole,
    getCurrentUserName,
    isAuthenticated,
    onAuthStateChanged,
    validateCustomerEmail,
    changePassword,
    validateChangePasswordHash,
    getPepper,
    isUserInRole,
    isSystemAdminOrOwner,
    isDealCustomerAdmin,
    isDealCustomerUser,
    isCompanyAdmin,
    isTrainReplacementCustomer,
    isTrafficControlUser,
    isTrainReplacementAdmin
};;
export default authService;