import React from 'react';
import ReactDOM from "react-dom/client";
import './index.scss';
import {
  Switch,
  Route,
  BrowserRouter
} from "react-router-dom";
import './i18n';
import { ToastContainer, toast } from 'react-toastify';
import { QueryClient, QueryClientProvider } from 'react-query';
import axios, { AxiosHeaders } from 'axios';
import authService from './app/services/auth/auth.service';
import i18n from './i18n';
import AppComponent from './app/app';
import { pepperHeader } from './app/helpers/pepperHeader/pepper-header';
import "./extensions"

axios.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response.status == 401) {
    authService.logout();
    window.location.href = '/auth/login';
  }
  if (error.response.status == 400 && error.response.data.errorCode == 113) {
    authService.getPepper();
  }
  return Promise.reject(error);
});
axios.interceptors.request.use(function (config) {
  if (!config.url?.includes("wp-json")) {
    config.headers['Cache-Control'] = "no-cache";
    config.headers['Accept-Language'] = i18n.language ?? "sv";
    config.headers['Cache-Control'] = "no-cache";
    config.headers = new AxiosHeaders({ ...config.headers, ...pepperHeader(config.data, config.data ? undefined : config.params) })
  }
  return config;
});
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
const queryClient = new QueryClient();
root.render(
  // <React.StrictMode>

    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <ToastContainer rtl={false} closeButton={true} position={'bottom-left'}/>
      
        <Switch>
          <Route path={"/:locale(en|sv)?/"}>
            <AppComponent />
          </Route>
        </Switch>

      </QueryClientProvider>
    </BrowserRouter>

  // </React.StrictMode>
);
